.m-1 {
  margin: 5px 5px 5px 5px;
}

.m-2 {
  margin: 10px 10px 10px 10px;
}

.m-3 {
  margin: 20px 20px 20px 20px;
}

.m-4 {
  margin: 40px 40px 40px 40px;
}

.p-0 {
  padding: 0px 0px 0px 0px;
}

.p-1 {
  padding: 5px 5px 5px 5px;
}

.p-2 {
  padding: 10px 10px 10px 10px;
}

.p-3 {
  padding: 20px 20px 20px 20px;
}

.p-4 {
  padding: 40px 40px 40px 40px;
}

.white-space-normal {
  white-space: normal;
}

.spacing-label {
  margin: calc(var(--page-margin)) var(--page-margin) var(--page-margin);
  padding-bottom: var(--page-margin);
}


ion-title {
  // Adding !important to force precedence in SSR
  font-weight: 400 !important;
  letter-spacing: 0.4px !important;
  line-height: 55px;
}

// Override Ionic styles
.input-clear-icon {

  // Change these properties so the Ionic close icon aligns with show/hide password
  &.sc-ion-input-ios,
  &.sc-ion-input-md {
    width: calc(1.2rem);
    margin-inline-start: 0.5rem;
  }
}

// temporary fix for https://github.com/ionic-team/ionic-framework/issues/18641
ion-picker {
  touch-action: none;
}

.recover-message {
  color: var(--ion-color-medium);
  margin: calc(var(--page-margin) / 2) 0px;
}

.subtitle-message {
  color: var(--ion-color-medium);
  margin: calc(var(--page-margin) / 2) 0px;
}

.details-description-wrapper {
  padding-top: 0px;
  padding-left: var(--page-margin);
  padding-right: var(--page-margin);
  padding-bottom: calc(var(--page-margin) * 2);

  .details-description {
    margin: 0px;
    line-height: 1.3;
    font-size: 14px;
    color: var(--ion-color-medium-shade);
  }
}

ion-header {
  ion-title {
    padding: 0px 0px 0px 0px;
  }
}

// footer
.user-info-wrapper {
  border-bottom: 1px solid var(--ion-item-border-color,
      var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));

  .stat-value h4 {
    font-size: 10pt;
  }

  .stat-value:nth-child(3) {
    font-size: 11pt;
    text-align: center;
  }
}

.user-stats-section .user-stats-wrapper {
  text-align: left;
}

// .static-call-footer {
//   position: fixed;
//   bottom: 0px;
//   background-color: var(--app-background);
//   margin: 0 0 0 0;
//   padding: 20px 20px 20px 20px;
//   border-top: 1px solid
//     var(
//       --ion-item-border-color,
//       var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13)))
//     );
// }
app-admin {
  width: 100%;
}

ion-card-subtitle {
  text-transform: none !important;
}

.mar-top-10 {
  margin-top: 10px;
}

.mar-top-30 {
  margin-top: 30px;
}

.mar-top-50 {
  margin-top: 50px;
}

.mar-bottom-footer-10 {
  margin-bottom: 10px !important;
}

.mar-bottom-footer {
  margin-bottom: 35px;
}

.mar-bottom-footer-100 {
  margin-bottom: 100px;
}

::-webkit-scrollbar,
*::-webkit-scrollbar {
  display: none;
  overflow: hidden;
}

// ion-content {
//   overflow: scroll;
//   --overflow: scroll;
// }

// .scroll-content {
//   overflow: hidden;
// }
ion-infinite-scroll.md.infinite-scroll-enabled.hydrated {
  overflow: scroll !important;
  height: 100% !important;
}

.web-black ion-toolbar {
  --background: rgba(35, 39, 42, 255);
}

.web-black ion-toolbar ion-menu-button {
  color: white;
}

.header-web-button {
  color: white;
}

ion-toolbar {
  --background: transparent;
}

ion-toolbar ion-title {
  color: var(--ion-color-dark);
}

ion-toolbar ion-buttons {
  color: var(--ion-color-dark);
}

ion-row {
  width: 100%;
}

ion-item-divider {
  background: transparent;
}

ion-content {
  background: var(--app-background);

}

ion-button {
  --border-radius: 30px;
}

app-footer {
  background-color: var(--ion-color-medium-shade);
}



.app-content ion-menu-toggle ion-item {
  margin: 10px 10px 10px 0px;
}

.border-bottom {
  border-bottom: 1px solid var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));

}

.w-100 {
  width: 100%;
}

.card-box-shadow {
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
}

.image-swiper {
  width: 100%;
  height: 85vh;
}

.ion-page {
  // display: block;
}

.text-center {
  text-align: center;
}

.card-container-background {
  margin-top: 20px;
  margin-bottom: 50px;
  -webkit-box-shadow: 10px 10px 53px -30px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 53px -30px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 53px -30px rgba(0, 0, 0, 0.75);
}

.backdrop-filter {
  backdrop-filter: blur(120px);
}

.toast-error {
  width: 100%;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.mar-left-10 {
  margin-left: 10px;
}

.mar-left-20 {
  margin-left: 20px;
}

.web-black {}

.centered {
  display: block;
  margin: auto;
  text-align: center;
}