@import url("https://fonts.googleapis.com/css2?family=Assistant&family=Bacasime+Antique&family=Libre+Baskerville&display=swap");
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import 'swiper/swiper.min.css';
:root {
  --ion-color-facebook: #3b5998;
  --ion-color-facebook-rgb: 59,89,152;
  --ion-color-facebook-contrast: #ffffff;
  --ion-color-facebook-contrast-rgb: 255,255,255;
  --ion-color-facebook-shade: #344e86;
  --ion-color-facebook-tint: #4f6aa2;
  --ion-color-google: #cc181e;
  --ion-color-google-rgb: 204,24,30;
  --ion-color-google-contrast: #ffffff;
  --ion-color-google-contrast-rgb: 255,255,255;
  --ion-color-google-shade: #b4151a;
  --ion-color-google-tint: #d12f35;
  --ion-color-twitter: #00aced;
  --ion-color-twitter-rgb: 0,172,237;
  --ion-color-twitter-contrast: #ffffff;
  --ion-color-twitter-contrast-rgb: 255,255,255;
  --ion-color-twitter-shade: #0097d1;
  --ion-color-twitter-tint: #1ab4ef;
}
:root .ion-color-facebook {
  --ion-color-base: var(--ion-color-facebook) !important;
  --ion-color-base-rgb: var(--ion-color-facebook-rgb) !important;
  --ion-color-contrast: var(--ion-color-facebook-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-facebook-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-facebook-shade) !important;
  --ion-color-tint: var(--ion-color-facebook-tint) !important;
}
:root .ion-color-google {
  --ion-color-base: var(--ion-color-google) !important;
  --ion-color-base-rgb: var(--ion-color-google-rgb) !important;
  --ion-color-contrast: var(--ion-color-google-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-google-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-google-shade) !important;
  --ion-color-tint: var(--ion-color-google-tint) !important;
}
:root .ion-color-twitter {
  --ion-color-base: var(--ion-color-twitter) !important;
  --ion-color-base-rgb: var(--ion-color-twitter-rgb) !important;
  --ion-color-contrast: var(--ion-color-twitter-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-twitter-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-twitter-shade) !important;
  --ion-color-tint: var(--ion-color-twitter-tint) !important;
}

.m-1 {
  margin: 5px 5px 5px 5px;
}

.m-2 {
  margin: 10px 10px 10px 10px;
}

.m-3 {
  margin: 20px 20px 20px 20px;
}

.m-4 {
  margin: 40px 40px 40px 40px;
}

.p-0 {
  padding: 0px 0px 0px 0px;
}

.p-1 {
  padding: 5px 5px 5px 5px;
}

.p-2 {
  padding: 10px 10px 10px 10px;
}

.p-3 {
  padding: 20px 20px 20px 20px;
}

.p-4 {
  padding: 40px 40px 40px 40px;
}

.white-space-normal {
  white-space: normal;
}

.spacing-label {
  margin: calc(var(--page-margin)) var(--page-margin) var(--page-margin);
  padding-bottom: var(--page-margin);
}

ion-title {
  font-weight: 400 !important;
  letter-spacing: 0.4px !important;
  line-height: 55px;
}

.input-clear-icon.sc-ion-input-ios, .input-clear-icon.sc-ion-input-md {
  width: 1.2rem;
  margin-inline-start: 0.5rem;
}

ion-picker {
  touch-action: none;
}

.recover-message {
  color: var(--ion-color-medium);
  margin: calc(var(--page-margin) / 2) 0px;
}

.subtitle-message {
  color: var(--ion-color-medium);
  margin: calc(var(--page-margin) / 2) 0px;
}

.details-description-wrapper {
  padding-top: 0px;
  padding-left: var(--page-margin);
  padding-right: var(--page-margin);
  padding-bottom: calc(var(--page-margin) * 2);
}
.details-description-wrapper .details-description {
  margin: 0px;
  line-height: 1.3;
  font-size: 14px;
  color: var(--ion-color-medium-shade);
}

ion-header ion-title {
  padding: 0px 0px 0px 0px;
}

.user-info-wrapper {
  border-bottom: 1px solid var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
}
.user-info-wrapper .stat-value h4 {
  font-size: 10pt;
}
.user-info-wrapper .stat-value:nth-child(3) {
  font-size: 11pt;
  text-align: center;
}

.user-stats-section .user-stats-wrapper {
  text-align: left;
}

app-admin {
  width: 100%;
}

ion-card-subtitle {
  text-transform: none !important;
}

.mar-top-10 {
  margin-top: 10px;
}

.mar-top-30 {
  margin-top: 30px;
}

.mar-top-50 {
  margin-top: 50px;
}

.mar-bottom-footer-10 {
  margin-bottom: 10px !important;
}

.mar-bottom-footer {
  margin-bottom: 35px;
}

.mar-bottom-footer-100 {
  margin-bottom: 100px;
}

::-webkit-scrollbar,
*::-webkit-scrollbar {
  display: none;
  overflow: hidden;
}

ion-infinite-scroll.md.infinite-scroll-enabled.hydrated {
  overflow: scroll !important;
  height: 100% !important;
}

.web-black ion-toolbar {
  --background: rgba(35, 39, 42, 255);
}

.web-black ion-toolbar ion-menu-button {
  color: white;
}

.header-web-button {
  color: white;
}

ion-toolbar {
  --background: transparent;
}

ion-toolbar ion-title {
  color: var(--ion-color-dark);
}

ion-toolbar ion-buttons {
  color: var(--ion-color-dark);
}

ion-row {
  width: 100%;
}

ion-item-divider {
  background: transparent;
}

ion-content {
  background: var(--app-background);
}

ion-button {
  --border-radius: 30px;
}

app-footer {
  background-color: var(--ion-color-medium-shade);
}

.app-content ion-menu-toggle ion-item {
  margin: 10px 10px 10px 0px;
}

.border-bottom {
  border-bottom: 1px solid var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
}

.w-100 {
  width: 100%;
}

.card-box-shadow {
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
}

.image-swiper {
  width: 100%;
  height: 85vh;
}

.text-center {
  text-align: center;
}

.card-container-background {
  margin-top: 20px;
  margin-bottom: 50px;
  -webkit-box-shadow: 10px 10px 53px -30px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 53px -30px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 53px -30px rgba(0, 0, 0, 0.75);
}

.backdrop-filter {
  backdrop-filter: blur(120px);
}

.toast-error {
  width: 100%;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.mar-left-10 {
  margin-left: 10px;
}

.mar-left-20 {
  margin-left: 20px;
}

.centered {
  display: block;
  margin: auto;
  text-align: center;
}

:host-context(.ios) .user-details-section .user-image-wrapper {
  max-width: 30%;
}

:host-context(.md) .user-details-section .user-image-wrapper {
  max-width: 28%;
}

:host {
  --page-margin: var(--app-narrow-margin);
  --page-border-radius: var(--app-fair-radius);
  --page-actions-padding: 5px;
  --page-friends-gutter: calc(var(--page-margin) / 2);
  --page-pictures-gutter: calc(var(--page-margin) / 2);
}

.user-details-section {
  --ion-grid-column-padding: 0px;
  margin: var(--page-margin);
}
.user-details-section .user-image-wrapper {
  max-width: 26%;
}
.user-details-section .user-info-wrapper {
  padding-left: var(--page-margin);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.user-details-section .user-info-wrapper .user-data-row {
  padding-bottom: var(--page-margin);
  flex-wrap: nowrap;
  justify-content: space-between;
}
.user-details-section .user-info-wrapper .user-data-row .user-name {
  margin: 0px 0px 5px;
  font-size: 20px;
  letter-spacing: 0.5px;
}
.user-details-section .user-info-wrapper .user-data-row .user-title {
  margin: 0px;
  color: var(--ion-color-medium);
  font-size: 12px;
}
.user-details-section .user-info-wrapper .user-data-row .membership-col {
  padding-left: var(--page-margin);
  flex-grow: 0;
}
.user-details-section .user-info-wrapper .user-data-row .user-membership {
  display: block;
  background-color: var(--ion-color-secondary);
  color: var(--ion-color-lightest);
  border-radius: var(--app-narrow-radius);
  padding: 4px 8px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
}
.user-details-section .user-info-wrapper .actions-row {
  --ion-grid-columns: 10;
  --ion-grid-column-padding: var(--page-actions-padding);
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-left: calc(var(--page-actions-padding) * -1);
  margin-right: calc(var(--page-actions-padding) * -1);
}
.user-details-section .user-info-wrapper .actions-row .main-actions {
  flex-grow: 0;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-left: calc(var(--page-actions-padding) * -1);
  margin-right: calc(var(--page-actions-padding) * -1);
  display: flex;
}
.user-details-section .user-info-wrapper .actions-row .main-actions .call-to-action-btn {
  padding: 0px var(--page-actions-padding);
  margin: 0px;
}
.user-details-section .user-info-wrapper .actions-row .secondary-actions {
  flex-grow: 0;
  padding-top: 0px;
  padding-bottom: 0px;
}
.user-details-section .user-info-wrapper .actions-row .secondary-actions .more-btn {
  --padding-start: 4px;
  --padding-end: 4px;
  margin: 0px;
}

.user-stats-section {
  --ion-grid-column-padding: 0px;
  margin: calc(var(--page-margin)) 0 var(--page-margin);
  padding-bottom: var(--page-margin);
}
.user-stats-section .user-stats-wrapper {
  text-align: left;
}
.user-stats-section .user-stats-wrapper .stat-value {
  display: block;
  padding-bottom: 5px;
  color: var(--ion-color-dark-shade);
  font-weight: 500;
  font-size: 12px;
  text-align: left;
}
.user-stats-section .user-stats-wrapper .stat-value-center {
  display: block;
  padding-bottom: 5px;
  color: var(--ion-color-dark-shade);
  font-weight: 500;
  font-size: 12px;
  text-align: center;
}
.user-stats-section .user-stats-wrapper .stat-name {
  font-size: 14px;
  color: var(--ion-color-medium);
}

.details-section-title {
  font-size: 18px;
  font-weight: 500;
  color: var(--ion-color-dark-tint);
  margin: 0px 0px var(--page-margin);
}

.user-about-section {
  margin: calc(var(--page-margin) * 2) var(--page-margin) var(--page-margin);
  padding-bottom: var(--page-margin);
  border-bottom: 1px solid var(--ion-color-light-shade);
}
.user-about-section .user-description {
  color: var(--ion-color-dark-shade);
  text-align: justify;
  margin: var(--page-margin) 0px;
  font-size: 14px;
  line-height: 1.3;
}

.user-friends-section {
  margin: calc(var(--page-margin) * 2) var(--page-margin) var(--page-margin);
  padding-bottom: var(--page-margin);
  border-bottom: 1px solid var(--ion-color-light-shade);
}
.user-friends-section .heading-row {
  margin-bottom: var(--page-margin);
  justify-content: space-between;
  align-items: center;
}
.user-friends-section .heading-row .details-section-title {
  margin: 0px;
}
.user-friends-section .heading-row .heading-call-to-action {
  padding: calc(var(--page-margin) / 2) calc(var(--page-margin) / 4);
  text-decoration: none;
  color: var(--ion-color-secondary);
}
.user-friends-section .friends-row {
  --ion-grid-columns: 7;
  --ion-grid-column-padding: 0px;
  flex-wrap: nowrap;
  overflow-x: scroll;
  will-change: scroll-position;
  margin-left: calc(var(--page-margin) * -1);
  margin-right: calc(var(--page-margin) * -1);
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
}
.user-friends-section .friends-row::-webkit-scrollbar {
  display: none;
}
.user-friends-section .friends-row::before, .user-friends-section .friends-row::after {
  content: "";
  flex: 0 0 calc(var(--page-margin) - var(--page-friends-gutter));
}
.user-friends-section .friends-row .friend-item {
  --col-item-width: 2;
  flex: 0 0 calc(var(--col-item-width) / var(--ion-grid-columns, 12) * 100%);
  width: calc(var(--col-item-width) / var(--ion-grid-columns, 12) * 100%);
  max-width: calc(var(--col-item-width) / var(--ion-grid-columns, 12) * 100%);
  padding: 0px var(--page-friends-gutter);
}
.user-friends-section .friends-row .friend-item .friend-name {
  display: block;
  text-align: center;
  margin: var(--page-margin) 0px;
  font-size: 14px;
}

.user-photos-section {
  margin: calc(var(--page-margin) * 2) var(--page-margin) var(--page-margin);
  padding-bottom: calc(var(--page-margin) * 2);
}
.user-photos-section .heading-row {
  margin-bottom: var(--page-margin);
  justify-content: space-between;
  align-items: center;
}
.user-photos-section .heading-row .details-section-title {
  margin: 0px;
}
.user-photos-section .heading-row .heading-call-to-action {
  padding: calc(var(--page-margin) / 2) calc(var(--page-margin) / 4);
  text-decoration: none;
  color: var(--ion-color-secondary);
}
.user-photos-section .pictures-row {
  --ion-grid-columns: 4;
  --ion-grid-column-padding: 0px;
  margin: 0px calc(var(--page-pictures-gutter) * -1);
}
.user-photos-section .pictures-row .picture-item {
  --col-item-width: 2;
  flex: 0 0 calc(var(--col-item-width) / var(--ion-grid-columns, 12) * 100%);
  width: calc(var(--col-item-width) / var(--ion-grid-columns, 12) * 100%);
  max-width: calc(var(--col-item-width) / var(--ion-grid-columns, 12) * 100%);
  padding: 0px var(--page-pictures-gutter) calc(var(--page-pictures-gutter) * 2);
}

app-image-shell.user-image {
  --image-shell-border-radius: var(--page-border-radius);
}

app-image-shell.friend-picture {
  --image-shell-border-radius: var(--page-border-radius);
}

app-image-shell.user-photo-image {
  --image-shell-border-radius: var(--page-border-radius);
}

.transparent-modal {
  --background: rgba(44, 39, 45, 0.2);
}
.transparent-modal::part(content) {
  backdrop-filter: blur(12px);
}

.ios .header-menu-title {
  margin-left: 40px;
}

.monthview-eventdetail-timecolumn {
  overflow: initial !important;
}