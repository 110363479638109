// http://ionicframework.com/docs/theming/
@import url('https://fonts.googleapis.com/css2?family=Assistant&family=Bacasime+Antique&family=Libre+Baskerville&display=swap');
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';

@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

// EXTRA GLOBAL STYLES
// Add custom Ionic Colors
@import 'theme/custom-ion-colors.scss';
// Add base app styles
@import 'theme/app-defaults.scss';

@import './app/user/profile/styles/user-profile.ios.scss';
@import './app/user/profile/styles/user-profile.md.scss';
@import './app/user/profile/styles/user-profile.page.scss';
@import './app/user/profile/styles/user-profile.shell.scss';
// global.scss
@import 'swiper/swiper.min.css';


.transparent-modal {
    --background: rgba(44, 39, 45, 0.2);

    &::part(content) {
        backdrop-filter: blur(12px)
    }
}

.ios {
    .header-menu-title {
        margin-left: 40px;
    }
}

.monthview-eventdetail-timecolumn {
    overflow: initial !important;
}