app-image-shell.user-image {
  --image-shell-border-radius: var(--page-border-radius);
}

app-image-shell.friend-picture {
  --image-shell-border-radius: var(--page-border-radius);
}

app-image-shell.user-photo-image {
  --image-shell-border-radius: var(--page-border-radius);
}
