// Hide scrollbars: https://stackoverflow.com/a/38994837/1116959
@mixin hide-scrollbars() {
  // IE 10+
  -ms-overflow-style: none;

  // Firefox
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;

  // Safari and Chrome
  &::-webkit-scrollbar {
    display: none;
  }
}
